<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-request-a-match"
      v-model="showModal"
      size="md"
      no-close-on-backdrop
      centered
      cancel-variant="outline-secondary"
      @hidden="onHidden"
    >
      <b-form>
        <b-row class="mb-1">
          <b-col
            sm="12"
            class="mb-3"
          >
            <h4 class="text-center">
              Personalise your Match Request Message
            </h4>
          </b-col>
          <b-col
            sm="12"
            offset="3"
          >
            <b-row>
              <b-col
                sm="2" 
              >
                <b-avatar
                  v-if="requestedUser && requestedUser.shortlistee"
                  class="mr-1"
                  variant="primary"
                  :text="userInitials(requestedUser.shortlistee)"
                  :src="userProfileImage(requestedUser.shortlistee)" 
                  size="3rem"
                />
                <b-avatar
                  v-if="requestedUser && !requestedUser.shortlistee"
                  class="mr-1"
                  variant="primary"
                  :text="userInitials(requestedUser)"
                  :src="userProfileImage(requestedUser)" 
                  size="3rem"
                />
              </b-col>
              <b-col
                sm="8"
                class="ml-n2"
              >
                <span v-if="requestedUser && requestedUser.shortlistee">{{ requestedUser.shortlistee.full_name }}</span>
                <span v-if="requestedUser && !requestedUser.shortlistee">{{ requestedUser.full_name }}</span>
                <p>
                  <small
                    class="text-muted text-right text-truncate w-100"
                  >
                    {{ mentorDisplay }}
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
          >
            <p>Increase your chance of acceptance by explaining why you want to connect...</p>
         
            <validation-provider
              v-slot="validationContext"
              ref="matchRequestMessage"
              rules="required"
              name="Match Request Message"
            >
              <b-form-textarea
                id="match-request-message"
                v-model="matchRequestMsg"
                placeholder="Description..."
                rows="6"
              />
              <b-form-invalid-feedback
                :state="validationContext.errors.length === 0"
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>            
      </b-form>
      <template>
        <b-button
          size="md"
          class="mx-auto justify-content-center"
          variant="primary"
          type="submit"
          @click="handleSubmit(sendMatchRequest)"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>
            <feather-icon
              class="mr-1"
              icon="SendIcon"
              size="16"
            />Send</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>
        
<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  VBTooltip, 
  BFormTextarea,
  BAvatar,
  BFormInvalidFeedback,
  BSpinner
} from "bootstrap-vue";
import {
  getValidationState
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import { userRoles } from '@models';
import { modService } from '@services';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";

export default {
  name: "RequestAMatch",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BFormTextarea,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BSpinner
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    },
    requestedUser: {
      type: Object,
      default:() => {},
      required: true
    }
  },
  data() {
    return {
      showModal: false, 
      isLoading: false,
      matchRequestMsg: '',
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    ...mapGetters('participants', ['mentorDisplay', 'menteeDisplay'])
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.matchRequestMsg = '';
      this.$emit('clear');
    },
    userInitials(user) {
      if(user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    async sendMatchRequest() {
      if(!this.user.id || !this.requestedUser.id) {
        return;
      }
      try {
        this.isUpdating = true;
        const programId = this.defaultProgramId;
        const matchRequest = {
          request_message: this.matchRequestMsg,
          requestee_id: this.requestedUser.id
        };
        const response = await modService.createMatchRequest(programId, matchRequest);
        const { data } = response;
        this.$toast(makeSuccessToast("Match Request Sent."));
        this.clearForm();
        this.$emit('match-request-sent', data);
      } catch (e) {
        const { data} = e.response;
        console.log(e);
        this.$toast(makeErrorToast(data?.message));
      } finally {
        this.isUpdating = false;
      }
    }
  },
  setup() {
    return {
      getValidationState,
      userRoles
    };
  },
};
</script>