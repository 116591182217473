<template>
  <b-card>
    <aom-skeleton-loader v-if="isLoadingUser || isFetchingUser" />
    <section v-if="!localUser.id && !isLoadingUser && !isFetchingUser">
      <div class="text-center m-4">
        <b-avatar
          size="72"
          variant="primary"
          class="mr-2"
        >
          <feather-icon
            icon="UserIcon"
            size="42"
          />
        </b-avatar>
        <h3 class="mt-1">
          No User selected or found
        </h3>
      </div>
    </section>
    <div v-if="localUser.id && !isLoadingUser">    
      <!-- Actions -->
      <b-row>
        <b-col
          sm="12"
          class="d-flex justify-content-between mb-3"
        >
          <b-button          
            variant="outline-primary"
            size="md"
            class="d-flex align-items-center justify-content-center border-right-none w-100"
            @click="compareUsers"
          >
            <aom-icon
              icon="PersonSearchOutline"
              class="mr-50"
              size="24"
            />
            <span>Compare</span>
          </b-button>
          <!-- Shortlist -->
          <template v-if="showShortlistWithMentee">
            <b-button
              v-if="!userIsShortListed(localUser)"
              variant="outline-primary"
              size="md"
              class="d-flex align-items-center justify-content-center border-left-none border-right-none button-focus-none w-100"
              @click="addAndRemoveShortList(shortListActions.ADD)"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-50"
                size="24"
                style="min-width: 20px"
              />
              Shortlist
            </b-button>
            <b-button
              v-else
              variant="primary"
              size="md"
              style="background-color: rgba(60, 111, 149, 0.5) !important"
              class="d-flex align-items-center justify-content-center border-left-none border-right-none w-100"
              @click="addAndRemoveShortList(shortListActions.REMOVE)"
            >
              <feather-icon
                icon="StarIcon"
                class="fill-icon mr-50"
                size="24"
                style="min-width: 20px"
              />
              Shortlist
            </b-button>
          </template>
          <!-- End Shortlist -->
          <b-button
            v-if="!user.requestee && !user.status_id && !userAsRoleIsMentor"
            variant="outline-primary"
            size="md"
            :disabled="!canRequestAMatch"
            class="d-flex align-items-center justify-content-center border-left-none w-100"
            @click="requestAMatch"
          >
            <aom-icon
              icon="ChatOutlineRounded"
              class="mr-50"
              size="24"
            />
            Request match
          </b-button>
          <b-button
            v-if="matchStatusPending && canWithDrawMatch"
            size="md"
            variant="outline-primary"
            class="d-flex align-items-center justify-content-center border-left-none w-100"
            @click="withDrawRequest"
          >
            <aom-icon
              icon="ChatOutlineRounded"
              class="mr-50"
              size="24"
            />
            Withdraw Request
          </b-button>
          <b-button
            v-if="matchStatusPending && canAcceptMatch"
            size="sm"
            variant="outline-primary"
            class="d-flex align-items-center justify-content-center border-left-none border-right-none w-100"
            @click="acceptRequest"
          >
            <aom-icon
              icon="HandBackRightOutline"
              class="mr-50"
              size="24"
            />
            Accept match
          </b-button>
          <b-button
            v-if="matchStatusPending && canDeclineMatch"
            size="sm"
            variant="outline-primary"
            class="d-flex align-items-center justify-content-center border-left-none w-100"
            @click="declineRequest"
          >
            <aom-icon
              icon="HandBackRightOffOutline"
              class="mr-50"
              size="24"
            />
            Decline match
          </b-button>
        </b-col>
        <b-col
          sm="8"
          class="d-flex flex-row"
        >
          <b-avatar
            variant="primary"
            :text="userInitials(localUser)"
            :src="userProfileImage(localUser)" 
            size="3rem"
          />
          <h5 class="mt-50 ml-1">
            {{ localUser.full_name }}
          </h5>
        </b-col>
      </b-row>
      <!-- End Actions -->
      <b-row>
        <b-col
          sm="12"
          class="mt-2"
        >
          <ul class="list-style-none pl-0">
            <li
              v-for="question in suggestedUserVisbileQuestions"
              :key="question.id"
            >
              <div
                class="list-style-none"
              >
                <p style="font-weight: 700">
                  {{ getTranslationTextByUserLocale(question.translations, 'question_text') }}
                </p>
                <p style="color: #999">
                  <span v-if="(question.type_id === questionTypes.TEXT && question.answers.length > 0)">{{ question.answers[0].answer_text }}</span>
                  <span v-if="(question.type_id === questionTypes.SCALE)">{{ getStatementsChoicesTranslation(question) }}</span>
                  <span v-if="(question.type_id === questionTypes.CHOICE || question.type_id === questionTypes.MATCHING)">
                    <ul
                      v-for="(answer, index) in getQuestionChoicesAnswerTextTranslation(question)"
                      :key="index"
                      class="list-style-none pl-50"
                    >
                      <li>
                        {{ answer }}
                      </li>
                    </ul>
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>
    <request-a-match
      v-if="!user.requestee && canRequestAMatch"
      ref="requestAMatch"
      :user="menteeUser"
      :requested-user="mentorUser"
      @match-request-sent="$emit('match-request-sent')"
    />
    <compare-my-profile
      ref="compareProfile"
      :mentee-user="menteeUser"
      :mentor-user="mentorUser"
    />
    <with-draw-request
      v-if="canWithDrawMatch && matchStatusPending"
      ref="withDrawRequest"
      :user="menteeUser"
      :match-request-id="matchRequestId"
      :requested-user="mentorUser"
      @match-request-withdrawn="$emit('match-request-withdrawn')"
    />
    <accept-match-request
      v-if="matchStatusPending && canAcceptMatch"
      ref="acceptRequest"
      :user="mentorUser"
      :requested-user="menteeUser"
      :match-request-id="matchRequestId"
      @clear="$emit('clear')"
      @match-request-accepted="$emit('match-request-accepted')"
    />
    <decline-match-request
      v-if="matchStatusPending & canDeclineMatch"
      ref="declineRequest"
      :user="mentorUser"
      :requested-user="menteeUser"
      :match-request-id="matchRequestId"
      @clear="$emit('clear')"
      @match-request-declined="$emit('match-request-declined')"
    />
  </b-card>
</template>
<script>
import { BCard,BRow, BCol, BAvatar, BButton } from "bootstrap-vue";
import { mapGetters } from "vuex";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles, questionTypes, matchRequestDisplay, matchRequestStatus } from '@models';
import CompareMyProfile from './CompareMyProfile.vue';
import RequestAMatch from "./RequestAMatch.vue";
import { formatDateTimeFromIsoDateTime, makeSuccessToast, makeErrorToast } from "@/libs/utils";
import WithDrawRequest from "./WithDrawRequest.vue";
import AcceptMatchRequest from "./AcceptMatchRequest.vue";
import DeclineMatchRequest from "./DeclineMatchRequest.vue";
import { useHasUserReachedMatchLimit } from '@/views/apps/champion/matches/useHasUserReachedMatchLimit.js';
import { modService } from '@services';
import AomIcon from "@aom-core/AomIcon";

const shortListActions = {
  ADD: 0,
  REMOVE: 1
};

export default {
  name: "UserDetail",
  components: {
    BRow, 
    BCol, 
    BAvatar,
    BButton,
    BCard,
    AomSkeletonLoader,
    CompareMyProfile,
    RequestAMatch,
    WithDrawRequest,
    AcceptMatchRequest,
    DeclineMatchRequest,
    AomIcon
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    pendingRequestsCount: {
      type: Number,
      default: 0
    },
    roleId: {
      type: Number,
      default: userRoles.MENTEE
    },
    isFetchingUser: {
      type: Boolean
    } 
  },
  data() {
    return {
      isLoadingUser: false,
      suggestionList: [],
      menteeUser: {},
      mentorUser: {},
      localUser: {},
      matchRequestId: 0,
      shortListActions
    };
  },
  
  computed: {
    ...mapGetters("profile", ["profile", "preferredLanguageId"]),
    ...mapGetters("app", ["currentProgram"]),
    asRoleId () {
      return this.roleId;
    },
    userAsRoleIsMentor() {
      return this.asRoleId === userRoles.MENTOR;
    },
    suggestedUserVisbileQuestions () {
      if(this.user && this.user.user_forms) {
        return this.user?.user_forms[0]?.form.questions;
      }
      if(this.user && this.user.requestee && this.user.requestee.user_forms) {
        return this.user?.requestee.user_forms[0]?.form.questions;
      }
      if(this.user && this.user.requester && this.user.requester.user_forms) {
        return this.user?.requester.user_forms[0]?.form.questions;
      }
      if(this.user && this.user.shortlistee && this.user.shortlistee.user_forms) {
        return this.user?.shortlistee.user_forms[0]?.form.questions;
      }
      return [];
    },
    matchRequestMessages() {
      if(this.user.request_message && this.user.response_message) {
        return 2;
      }
      if(this.user.request_message || this.user.response_message) {
        return 1;
      }
      return 0;
    },
    matchStatusPending () {
      return this.user.status_id === matchRequestStatus.PENDING;
    },
    loggedUserIsMentor () {
      return this.profile.role === userRoles.MENTOR;
    },
    canAcceptMatch() {
      return Boolean(this.loggedUserIsMentor) && Boolean(this.user.requester);
    },
    canDeclineMatch() {
      return Boolean(this.loggedUserIsMentor) && Boolean(this.user.requester);
    },
    canWithDrawMatch() {
      return Boolean(this.user.requestee);
    },
    canRequestAMatch() {
      if(this.roleId === userRoles.MENTOR) {
        return false;
      }
      const menteeRole = this.profile.user_roles.filter(r => r.role_id === userRoles.MENTEE);
      if(menteeRole.length > 0) {
        return !useHasUserReachedMatchLimit(menteeRole[0]) && !this.hasUserReachedAllowedPendingRequests;
      }
      return false;
    },
    currentProgramRolePendingMatchLimit () {
      const programRole = this.currentProgram.program_roles.filter(r => r.role_id === this.roleId);
      if(programRole.length > 0) {
        return programRole[0].match_request_limit;
      }
      return 0;
    },
    hasUserReachedAllowedPendingRequests() {
      return this.pendingRequestsCount >= this.currentProgramRolePendingMatchLimit;
    },
    hasMenteeRole() {
      return this.profile.roles.some(role => role === userRoles.MENTEE);
    },
    hasMentorRole() {
      return this.profile.roles.some(role => role === userRoles.MENTOR);
    },
    showShortlistWithMentee() {
      return (
        this.hasMenteeRole &&
        !(this.localUser.requester_id || this.localUser.requestee_id)
      ) || this.hasMentorRole;
    }
  },
  watch: {
    user (n) {
      if(n && n.requestee) {
        this.localUser = {
          ...n,
          ...n.requestee
        }; 
      } else if(n && n.shortlistee){
        this.localUser = { 
          ...n,
          shortlisteeId: n.id,
          ...n.shortlistee 
        };
      } else if(n && n.requester) {
        this.localUser = {
          ...n,
          ...n.requester
        }; 
      } else {
        this.localUser = n;
      }
    }
  },
  methods: {
    lastUpdatedTime(date) {
      return formatDateTimeFromIsoDateTime(date, true);
    },
    userIsShortListed(user) {
      if(this.localUser.shortlisted_users_as_shortlistee) {
        return this.localUser.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.id).length > 0;
      }
      if(this.localUser.shortlistee) {
        return this.localUser.shortlistee.id === user.id;
      }
      if(this.localUser?.requestee) {
        return this.localUser.requestee.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.requestee.id).length > 0;
      }
      if(this.localUser?.requester) {
        return this.localUser.requester.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.requester.id).length > 0;
      }
      return;
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    userInitials(user) {
      if(user && user.first_name) {
        const firstInt = user? Array.from(user?.first_name)[0]: '';
        const lastInt = user? Array.from(user?.last_name)[0]: '';
        return `${firstInt}${lastInt}`;
      }
      return '';
    },
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters['participants/getTranslationTextByUserLocale'](translations, propertyName);
    },
    getQuestionChoicesAnswerTextTranslation(question) {
      if(question.choices.length > 0 ) {
        const choice = question.choices.flatMap(c => c.translations.filter(t => t.locale_id === this.preferredLanguageId));
        if(choice.length > 0 && question.answers.length > 0) {
          const ansIds = question.answers.map(c => c.choice_id);
          if(ansIds.length > 0) {
            const matchedAns = choice.filter(c => ansIds.includes(c.question_choice_id));
            return matchedAns.map(a => a.choice_text);
          }
        }
      }
      return;
    },
    getStatementsChoicesTranslation(question) {
      if(question.statements.length > 0 ) {
        const state = question.statements.filter(s => s.answers.length > 0);
        // find the translated choice
        if(state.length > 0) {
          const matchedChoice = question.choices.filter(c => c.id === state[0].answers[0].choice_id).shift();
          const choiceTrans = matchedChoice.translations.filter(t => t.locale_id === this.preferredLanguageId).shift();
          const stateTrans = state[0].translations.filter(t => t.locale_id === this.preferredLanguageId).shift();
          if(choiceTrans && stateTrans) {
            return `${stateTrans.question_text} - ${choiceTrans.choice_text}`;
          }
        }
        return;
      }
      return;
    },
    compareUsers() {
      this.setMenteeMentorUser();
      this.$refs.compareProfile.show();
    },
    requestAMatch() {
      this.setMenteeMentorUser();
      this.$refs.requestAMatch.show();
    },
    withDrawRequest() {
      this.setMenteeMentorUser();
      this.$refs.withDrawRequest.show();
      this.matchRequestId = this.user.id;
    },
    acceptRequest() {
      this.setMenteeMentorUser();
      this.matchRequestId = this.user.id;
      this.$refs.acceptRequest.show();
    },
    declineRequest() {
      this.setMenteeMentorUser();
      this.matchRequestId = this.user.id;
      this.$refs.declineRequest.show();
    },
    setMenteeMentorUser() {
      if(this.user.requester) {
        this.menteeUser = this.user.requester;
        this.mentorUser = this.profile;
      } else if(this.user.shortlistee && this.user.shortlister_role_id === userRoles.MENTEE) {
        this.menteeUser = this.profile;
        this.mentorUser = this.user.shortlistee;
      } else if(this.user.shortlistee && this.user.shortlister_role_id === userRoles.MENTOR) {
        this.menteeUser = this.user.shortlistee;
        this.mentorUser = this.profile;
      } else if(this.user.requestee) {
        this.menteeUser = this.profile;
        this.mentorUser = this.user.requestee;
      } else {
        this.mentorUser = this.user;
        this.menteeUser = this.profile;
      }
    },
    async addAndRemoveShortList(action) {
      if (action == shortListActions.ADD) {
        try {
          const programId = this.currentProgram?.id;
          const listees = {
            shortlister_role_id: this.roleId,
            shortlistee_id: this.localUser.id
          };
          await modService.addToShortlist(programId, listees);
          this.$emit('shortlist-added', this.user);
          this.$toast(makeSuccessToast('User added to shortlist'));
        } catch(e) {
          this.$toast(makeErrorToast(e));
          console.log(e);
        }
      } else {
        try {
          const programId = this.currentProgram?.id;
          const userId = this.localUser.shortlisteeId || this.localUser?.shortlisted_users_as_shortlistee[0].id;
          await modService.removeFromShortlist(programId, userId);
          this.$toast(makeSuccessToast('User removed from shortlist'));
          this.$emit('shortlist-removed', this.user);
          this.localUser = {};
        } catch(e) {
          this.$toast(makeErrorToast(e));
          console.log(e);
        }
      }
    }
  },
  setup() {
    return {
      questionTypes, 
      userRoles,
      matchRequestDisplay, 
      matchRequestStatus
    };
  }
};
</script>

<style lang="scss" scoped>
.suggested-user-list {
  cursor: pointer;
}
.selected {
  background: #f8f8f8;
  border-left: 5px solid var(--aom-color-secondary);
}
.list-style-none {
  list-style: none;
}

.border-right-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.border-left-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-focus-none {
  &:focus {
    background-color: transparent !important;
    color: #3c6f95;
  }
}
.fill-icon {
  fill: var(--aom-color-primary);
  color: var(--aom-color-primary);
}
</style>