<template>
  <section>
    <b-form-input
      :id="`type-search`"
      v-model="search"
      aria-placeholder="Keyword Search"
      placeholder="Keyword Search"
      type="search"
      class="mb-2"
    />
    <aom-skeleton-loader v-if="isLoading" />
    <b-list-group
      v-for="(u, index) in shortList"
      :key="index"
    >
      <b-list-group-item
        class="suggested-user-list"
        :class="{'selected': selected === u.id }"
        @click="fetchSuggestedUser(u.id)"
      > 
        <b-row>
          <b-col sm="8">
            <b-avatar
              class="mr-1"
              variant="primary"
              :text="userInitials(u.shortlistee)"
              :src="userProfileImage(u.shortlistee)" 
              size="3rem"
            />
            {{ u.shortlistee.full_name }}
          </b-col>
          <b-col
            sm="4"
            class="d-flex justify-content-end"
          >
            <b-avatar
              size="40"
              variant="primary"
              class="mr-1"
              style="background-color: rgba(60, 111, 149, 0.5)"
              rounded
            >
              <feather-icon
                class="fill-icon"
                icon="StarIcon"
                size="20"
              />
            </b-avatar>
            <b-button
              size="sm"
              variant="light"
              style="background-color: #e3e3e3"
            >
              View profile
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </section>
</template>
  
<script>
import {modService} from '@services';
import { BRow, BCol, BAvatar, BFormInput, BListGroup, BListGroupItem, BButton } from "bootstrap-vue";
import { mapGetters } from "vuex";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import { userRoles } from '@models';
import _debounce from "lodash/debounce";
  
export default {
  name: "ShortListUsers",
  components: {
    BRow, 
    BCol, 
    BAvatar,
    BFormInput,
    BButton,
    AomSkeletonLoader,
    BListGroup,
    BListGroupItem
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    }, 
    roleId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      selected: undefined,
      search: '',
      shortList: []
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("app", ["currentProgram"]),
    asRoleId () {
      return userRoles.MENTEE;
    }
  },
  watch: {
    search: _debounce(function () {
      this.getShortList();
    }, 500),
    shortList(n) {
      if(n && n.length > 0) {
        this.fetchUser(n[0].id);
      }
    }
  },
  created() {
    this.getShortList();
  },
  methods: {
    fetchSuggestedUser(id) {
      this.fetchUser(id);
    },
    userIsShortListed(user) {
      return user.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.id).length > 0;
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    userInitials(user) {
      if(user && user.first_name) {
        const firstInt = user? Array.from(user?.first_name)[0]: '';
        const lastInt = user? Array.from(user?.last_name)[0]: '';
        return `${firstInt}${lastInt}`;
      }
      return '';
    },
    async getShortList() {
      try {
        this.isLoading = true;
        let searchParams = {};
        if(this.search) {
          searchParams = {
            columnFilters: [{field: 'keyword', value: this.search}],
          };
        }
        const programId = this.currentProgram?.id;
        const response = await modService.listShortlistedUsers(programId, this.roleId, searchParams);
        const { data } = response;
        this.shortList = data?.items;
        this.selected = data.id;
      } catch(e) {
        const  { status, message } = e.response; 
        if(status === 409) {
          this.$toast(makeErrorToast(message));
        }
        this.$toast(makeErrorToast(message));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchUser(id) {
      try {
        this.isLoadingUser = true;
        const programId = this.currentProgram?.id;
        const response = await modService.getShortlistedUserDetail(programId, id);
        const { data } = response;
        this.selected = data.id;
        this.$emit('view', data);
      } catch(e) {
        this.$toast(makeErrorToast(e));
        console.log(e);
      } finally {
        this.isLoadingUser = false;
      }
    },
  },
  setup() {
    return {
    };
  }
};
</script>
  
  <style lang="scss" scoped>
  .suggested-user-list {
    cursor: pointer;
  }
  .selected {
    background: #f8f8f8;
    border-left: 5px solid var(--aom-color-secondary);
  }
  .list-style-none {
    list-style: none;
  }
  .fill-icon {
    fill: var(--aom-color-primary);
    color: var(--aom-color-primary);
  }
  </style>