<template>
  <b-modal
    id="modal-compare-my-profile"
    v-model="showModal"
    size="lg"
    :title="``"
    centered
    cancel-variant="outline-secondary"
    @hidden="onHidden"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col
          sm="12"
          class="mb-2"
        >
          <h3 class="text-center">
            Compare My Profile
          </h3>
          <h4 class="text-center text-muted">
            How well do you compare ?
          </h4>
        </b-col>
        <b-col
          sm="6"
        >
          <b-row v-if="menteeUser && menteeUser">
            <b-col sm="9">
              <span
                v-b-tooltip.hover.topright="menteeUser.full_name"
                class="mb-0 d-block text-right text-truncate w-100"
              >{{ menteeUser.full_name }}</span>
              <span
                class="mb-0 d-flex justify-content-end"
              ><small
                class="text-muted text-right text-truncate w-100"
              >
                {{ menteeDisplay }}
              </small>
              </span>
            </b-col>
            <b-col
              sm="3"
              class="d-flex justify-content-end"
            >
              <b-avatar
                variant="primary"
                :text="userInitials(menteeUser)"
                :src="userProfileImage(menteeUser)" 
                size="4rem"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="6"
        >
          <b-row v-if="mentorUser && mentorUser">
            <b-col sm="3">
              <b-avatar
                variant="primary"
                :text="userInitials(mentorUser)"
                :src="userProfileImage(mentorUser)" 
                size="4rem"
              />
            </b-col>
            <b-col sm="9">
              <span
                v-b-tooltip.hover.topleft="mentorUser.full_name"
                class="mb-0 d-block text-left text-truncate w-100"
              >{{ mentorUser.full_name }}</span>
              <span
                class="mb-0 d-flex justify-content-start"
              ><small
                class="text-muted text-left text-truncate w-100"
              >
                {{ mentorDisplay }}
              </small>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <b-card class="text-center">
            <section v-if="menteeUser">
              <b-row>
                <b-col sm="6">
                  <aom-languages align="center">
                    <template slot-scope="props">
                      <application-summary
                        :user="menteeUser"
                        :index="props.index"
                        :locale-id="props.localeId"
                        :questions="menteeQuestions"
                        :matched-users-questions="mentorQuestions"
                        text-direction="left"
                      />
                    </template>
                  </aom-languages>
                </b-col>
                <b-col sm="6">
                  <aom-languages align="center">
                    <template slot-scope="props">
                      <application-summary
                        :user="mentorUser"
                        :index="props.index"
                        :locale-id="props.localeId"
                        :questions="mentorQuestions"
                        :matched-users-questions="menteeQuestions"
                        text-direction="text-left"
                      />
                    </template>
                  </aom-languages>
                </b-col>
              </b-row>
            </section>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer="{cancel}">
      <b-button
        size="md"
        variant="outline-secondary"
        @click="cancel()"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>
      
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  BAvatar,
  VBTooltip
} from "bootstrap-vue";
import {
  getValidationState
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import { userRoles } from '@models';
import { matchesService } from '@services';
import AomLanguages from "@aom-core/AomLanguages";
import ApplicationSummary from "@/views/components/forms/applications/ApplicationSummary.vue";
import { makeErrorToast } from "@/libs/utils";
  
export default {
  name: "CompareMyProfile",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BAvatar,
    AomLanguages,
    ApplicationSummary
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    menteeUser: {
      type: Object,
      default:() => {},
      required: true
    },
    mentorUser: {
      type: Object,
      default:() => {},
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      isLoadingMatches: false,
      matchedUsers: [],
      menteeQuestions: [],
      mentorQuestions: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    ...mapGetters('participants', ['mentorDisplay', 'menteeDisplay'])
  },
  methods: {
    show() {
      this.showModal = true;
      this.$nextTick(() => {
        this.fetchMatchUsersQuestions();
      });
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.menteeQuestions = [];
      this.mentorQuestions = [];
      this.$emit('clear');
    },
    userInitials(user) {
      if(user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    async fetchMatchUsersQuestions() {
      if(!this.menteeUser.id || !this.mentorUser.id) {
        return;
      }
      const matchUsers = [
        {
          user_id: this.menteeUser.id,
          role_id: userRoles.MENTEE
        },
        {
          user_id: this.mentorUser.id,
          role_id: userRoles.MENTOR
        }
      ];
      const programId = this.defaultProgramId;
      try {
        this.isLoadingQuestions = true;
        const response = await matchesService.getCompareUsers(programId, matchUsers);
        const { data } = response;
        const menteeUser = data.match_users.filter(m => m.role_id === userRoles.MENTEE).shift();
        const mentorUser = data.match_users.filter(m => m.role_id === userRoles.MENTOR).shift();
        this.menteeQuestions = menteeUser?.user.user_forms[0]?.form?.questions;
        this.mentorQuestions = mentorUser?.user.user_forms[0]?.form?.questions;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching application questions'));
      } finally {
        this.isLoadingQuestions = false;
      }
    }
  },
  setup() {
    return {
      getValidationState,
      userRoles
    };
  },
};
</script>
