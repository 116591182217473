<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-decline-request"
      v-model="showModal"
      size="md"
      no-close-on-backdrop
      centered
      cancel-variant="outline-secondary"
      hide-footer
      @hidden="onHidden"
    >
      <b-form>
        <b-row class="mb-1">
          <b-col
            sm="12"
            class="mb-3"
          >
            <h5 class="text-center">
              Are you sure you want to decline this match request?
            </h5>
          </b-col>
          <b-col
            sm="12"
            offset="3"
          >
            <b-row>
              <b-col sm="2">
                <b-avatar
                  class="mr-1"
                  variant="primary"
                  :text="userInitials(requestedUser)"
                  :src="userProfileImage(requestedUser)"
                  size="3rem"
                />
              </b-col>
              <b-col
                sm="8"
                class="ml-n2"
              >
                {{ requestedUser.full_name }}
                <p>
                  <small class="text-muted text-right text-truncate w-100">
                    {{ mentorDisplay }}
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <p>
              Please personalise your message to
              {{ requestedUser.full_name }} declining the request.
            </p>
            <validation-provider
              v-slot="validationContext"
              ref="declineRequestMessage"
              rules="required"
              name="Decline Match Request Message"
            >
              <b-form-textarea
                id="decline-match-request-message"
                v-model="declineRequestMessage"
                placeholder="Description..."
                rows="6"
              />
              <b-form-invalid-feedback
                :state="validationContext.errors.length === 0"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <template>
        <b-button
          size="md"
          class="d-block mx-auto justify-content-center mt-1"
          variant="primary"
          type="submit"
          @click="handleSubmit(declineRequest)"
        >
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-if="isUpdating"> Updating...</span>
          <span v-else>
            <feather-icon
              class="mr-1"
              icon="SendIcon"
              size="16"
            />Send</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  VBTooltip,
  BFormTextarea,
  BAvatar,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import { mapGetters } from "vuex";
import { userRoles, matchRequestStatus } from "@models";
import { modService } from "@services";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "DeclineMatchRequest",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BFormTextarea,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    requestedUser: {
      type: Object,
      default: () => {},
      required: true,
    },
    matchRequestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      declineRequestMessage: "",
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId"]),
    ...mapGetters("participants", ["mentorDisplay", "menteeDisplay"]),
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.$emit("clear");
    },
    userInitials(user) {
      if (user && user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage(user) {
      return user?.profile_image?.url || "";
    },
    async declineRequest() {
      if (!this.user.id || !this.requestedUser.id) {
        return;
      }
      try {
        this.isUpdating = true;
        const programId = this.defaultProgramId;
        const matchRequest = {
          response_message: this.declineRequestMessage,
          status_id: matchRequestStatus.DECLINED,
        };
        const matchRequestId = this.matchRequestId;
        const response = await modService.updateMatchRequest(
          programId,
          matchRequestId,
          matchRequest
        );
        const { data } = response;
        this.$toast(makeSuccessToast("You have Declined this match."));
        this.clearForm();
        this.$emit("match-request-declined", data);
      } catch (e) {
        const { data } = e.response;
        console.log(e);
        this.$toast(makeErrorToast(data?.message));
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
      userRoles,
    };
  },
};
</script>
