<template>
  <section>
    <h5 class="pl-2 py-3 m-0">
      Pending Requests ({{ pendingRequests.length }})
    </h5>
    <aom-skeleton-loader v-if="isLoading" />
    <b-list-group
      v-for="(u) in pendingRequests"
      :key="u.id"
    >
      <b-list-group-item
        class="suggested-user-list"
        :class="{'selected': selected === u.id }"
        @click="fetchSuggestedUser(u.id)"
      > 
        <b-row>
          <b-col sm="8">
            <b-avatar
              v-if="u.requester"
              class="mr-1"
              variant="primary"
              :text="userInitials(u.requester)"
              :src="userProfileImage(u.requester)" 
              size="3rem"
            />
            <b-avatar
              v-if="u.requestee"
              class="mr-1"
              variant="primary"
              :text="userInitials(u.requestee)"
              :src="userProfileImage(u.requestee)" 
              size="3rem"
            />
            <span
              v-if="u.requestee"
              class="ml-100"
            >{{ u.requestee.full_name }}</span>
            <span
              v-if="u.requester"
              class="ml-100"
            >{{ u.requester.full_name }}</span>
          </b-col>
          <b-col
            sm="4"
            class="d-flex justify-content-end"
          >
            <b-avatar
              v-if="userIsShortListed(u) && showShortlistWithMentee(u)"
              size="40"
              variant="primary"
              class="mr-1"
              style="background-color: rgba(60, 111, 149, 0.5)"
              rounded
            >
              <feather-icon
                class="fill-icon"
                icon="StarIcon"
                size="20"
              />
            </b-avatar>
            <b-button
              size="sm"
              variant="light"
              style="background-color: #e3e3e3"
            >
              View profile
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            class="d-flex justify-content-end mt-1"
          >
            <b-badge
              variant="danger"
              class="text-align-left"
            >
              <small>{{ requestExpiresIn(u) }}</small>
            </b-badge>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <h5 class="pl-2 py-3 m-0">
      Request History ({{ historyRequests.length }})
    </h5>
    <aom-skeleton-loader v-if="isLoading" />
    <b-list-group
      v-for="(u) in historyRequests"
      :key="u.id"
    >
      <b-list-group-item
        class="suggested-user-list"
        :class="{'selected': selected === u.id }"
        @click="fetchSuggestedUser(u.id)"
      > 
        <b-row>
          <b-col sm="8">
            <b-avatar
              v-if="u.requestee"
              class="mr-1"
              variant="primary"
              :text="userInitials(u.requestee)"
              :src="userProfileImage(u.requestee)" 
              size="3rem"
            />
            <b-avatar
              v-if="u.requester"
              class="mr-1"
              variant="primary"
              :text="userInitials(u.requester)"
              :src="userProfileImage(u.requester)" 
              size="3rem"
            />
            <span
              v-if="u.requestee"
              class="ml-100"
            >{{ u.requestee.full_name }}</span>
            <span
              v-if="u.requester"
              class="ml-100"
            >{{ u.requester.full_name }}</span>
          </b-col>
          <b-col
            sm="4"
            class="d-flex justify-content-end"
          >
            <b-avatar
              v-if="userIsShortListed(u) && showShortlistWithMentee(u)"
              size="40"
              variant="primary"
              class="mr-1"
              style="background-color: rgba(60, 111, 149, 0.5)"
              rounded
            >
              <feather-icon
                class="fill-icon"
                icon="StarIcon"
                size="20"
              />
            </b-avatar>
            <b-button
              size="sm"
              variant="light"
              style="background-color: #e3e3e3"
            >
              View profile
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="isRequestWithDrawn(u)">
          <b-col
            sm="12"
            offset="1"
          >
            <h6 class="ml-1 text-muted">
              {{ statusText(u) }}
            </h6>
            <p class="mb-0 ml-1">
              <small>Date of Request: {{ formatDateTimeFromIsoDateTime(u.created_at) }}</small>
            </p>
            <p class="mb-0 ml-1">
              <small>Request Withdrawn: {{ formatDateTimeFromIsoDateTime(u.updated_at) }}</small>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="isRequestAccepted(u)">
          <b-col
            sm="12"
            offset="1"
          >
            <h6 class="ml-1 text-muted">
              {{ statusText(u) }}
            </h6>
            <p class="mb-0 ml-1">
              <small>Date of Request: {{ formatDateTimeFromIsoDateTime(u.created_at) }}</small>
            </p>
            <p class="mb-0 ml-1">
              <small>Request Accepted: {{ formatDateTimeFromIsoDateTime(u.updated_at) }}</small>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="isRequestDeclined(u)">
          <b-col
            sm="12"
            offset="1"
          >
            <h6 class="ml-1 text-muted">
              {{ statusText(u) }}
            </h6>
            <p class="mb-0 ml-1">
              <small>Date of Request: {{ formatDateTimeFromIsoDateTime(u.created_at) }}</small>
            </p>
            <p class="mb-0 ml-1">
              <small>Request Accepted: {{ formatDateTimeFromIsoDateTime(u.updated_at) }}</small>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="isRequestExpired(u)">
          <b-col
            sm="12"
            offset="1"
          >
            <h6 class="ml-1 text-muted">
              {{ statusText(u) }}
            </h6>
            <p class="mb-0 ml-1">
              <small>Date of Request: {{ formatDateTimeFromIsoDateTime(u.created_at) }}</small>
            </p>
            <p class="mb-0 ml-1">
              <small>Request Accepted: {{ formatDateTimeFromIsoDateTime(u.updated_at) }}</small>
            </p>
          </b-col>
        </b-row>
        <b-row v-if="u.requestee || u.requester">
          <b-col>
            <b-row>
              <b-col sm="12">
                <b-card
                  v-if="u.request_message"
                  bg-variant="white"
                  class="mt-2 mb-1"
                  body-class="shadow-sm"
                >
                  <p class="mb-0">
                    {{ u.request_message }}
                  </p>
                </b-card>
                <p class="d-flex mb-0">
                  <small>{{ lastUpdatedTime(u.created_at) }}</small>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-card
                  v-if="u.response_message"
                  bg-variant="primary"
                  class="mt-2 mb-1"
                  body-class="shadow-sm"
                >
                  <p class="text-white text-right mb-0">
                    {{ u.response_message }}
                  </p>
                </b-card>
                <p class="d-flex float-right mb-0">
                  <small>{{ lastUpdatedTime(u.updated_at) }}</small>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </section>
</template>
    
<script>
import {modService} from '@services';
import { BRow, BCol, BAvatar, BFormInput, BListGroup, BListGroupItem, BButton, BBadge, BCard } from "bootstrap-vue";
import { mapGetters } from "vuex";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import { userRoles, matchRequestStatus } from '@models';
import _debounce from "lodash/debounce";
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";

export default {
  name: "HistoryUsers",
  components: {
    BRow, 
    BCol, 
    BAvatar,
    BFormInput,
    BButton,
    AomSkeletonLoader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BCard
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    }, 
    roleId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      selected: undefined,
      search: '',
      shortList: [],
      pendingRequests: [],
      historyRequests: []
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("app", ["currentProgram"]),
    asRoleId () {
      return userRoles.MENTEE;
    },
    hasMenteeRole() {
      return this.profile.roles.some(role => role === userRoles.MENTEE);
    },
    hasMentorRole() {
      return this.profile.roles.some(role => role === userRoles.MENTOR);
    },
  },
  watch: {
    search: _debounce(function () {
      this.getPendingRequests();
    }, 500),
    historyRequests(n) {
      if(n && n.length > 0) {
        this.fetchSuggestedUser(n[0].id);
      }
    },
    pendingRequests(n) {
      if(n && n.length > 0) {
        this.fetchSuggestedUser(n[0].id);
      }
    },
  },
  created() {
    this.getPendingRequests();
  },
  methods: {
    requestExpiresIn(user) {
      if(Number(user.days_to_expiry) === 0 ) {
        return `Pending Request Expires today`;
      }
      return `Pending Request Expires in ${user.days_to_expiry} days`;
    },
    fetchSuggestedUser(id) {
      this.fetchMatchRequestUser(id);
    },
    userIsShortListed(user) {
      if(user?.requestee) {
        return user.requestee.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.requestee.id).length > 0;
      }
      if(user?.requester) {
        return user.requester.shortlisted_users_as_shortlistee.filter(su => su.shortlistee_id === user.requester.id).length > 0;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    userInitials(user) {
      if(user && user.first_name) {
        const firstInt = user? Array.from(user?.first_name)[0]: '';
        const lastInt = user? Array.from(user?.last_name)[0]: '';
        return `${firstInt}${lastInt}`;
      }
      return '';
    },
    async getPendingRequests() {
      try {
        this.isLoading = true;
        let searchParams = {};
        if(this.search) {
          searchParams = {
            columnFilters: [{field: 'keyword', value: this.search}],
          };
        }
        const programId = this.currentProgram?.id;
        const response = await modService.listMatchRequests(programId, this.roleId, searchParams);
        const { data } = response;
        this.pendingRequests = data?.items.filter(i => i.status_id === matchRequestStatus.PENDING);
        this.historyRequests = data?.items.filter(i => i.status_id !== matchRequestStatus.PENDING);
      } catch(e) {
        const  { status, message } = e.response; 
        if(status === 409) {
          this.$toast(makeErrorToast(message));
        }
        this.$toast(makeErrorToast(message));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchMatchRequestUser(id) {
      try {
        this.isLoadingUser = true;
        this.$emit('isLoadingUser', true);
        const programId = this.currentProgram?.id;
        const response = await modService.getMatchRequestUserDetail(programId,id);
        const { data } = response;
        this.selected = data.id;
        this.$emit('view', data);
      } catch(e) {
        this.$toast(makeErrorToast(e));
        console.log(e);
      } finally {
        this.$emit('isLoadingUser', false);
        this.isLoadingUser = false;
      }
    },
    isRequestWithDrawn(u) {
      return Number(u.status_id) === Number(matchRequestStatus.WITHDRAWN);
    },
    isRequestAccepted(u) {
      return Number(u.status_id) === Number(matchRequestStatus.ACCEPTED);
    },
    isRequestExpired(u) {
      return Number(u.status_id) === Number(matchRequestStatus.EXPIRED);
    },
    isRequestDeclined(u) {
      return Number(u.status_id) === Number(matchRequestStatus.DECLINED);
    },
    statusText(u) {
      const isUserRoleMentor = u.role_id === userRoles.MENTOR;
      switch (u.status_id) {
      case matchRequestStatus.ACCEPTED:
        if (isUserRoleMentor) {
          return 'Mentor accepted request';
        }
        return 'Accepted your request';
      case matchRequestStatus.DECLINED:
        if (isUserRoleMentor) {
          return 'Mentor declined request';
        }
        return 'Declined your request';
      case matchRequestStatus.WITHDRAWN:
        if (isUserRoleMentor) {
          return 'You withdraw request';
        }
        return 'Mentee withdraw request';
      case matchRequestStatus.EXPIRED:
        if (isUserRoleMentor) {
          return 'Your request expired';
        }
        return 'Mentee request expired';
      default:
        return ''; 
      }
    },
    lastUpdatedTime(date) {
      return formatDateTimeFromIsoDateTime(date, true);
    },
    showShortlistWithMentee(u) {
      return (
        this.hasMenteeRole &&
        !(u.requester_id || u.requestee_id)
      ) || this.hasMentorRole;
    }
  },
  setup() {
    return {
      formatDateTimeFromIsoDateTime
    };
  }
};
</script>
    
<style lang="scss" scoped>
    .suggested-user-list {
        cursor: pointer;
    }
    .selected {
        background: #f8f8f8;
        border-left: 5px solid var(--aom-color-secondary);
    }
    .list-style-none {
        list-style: none;
    }
    .fill-icon {
      fill: var(--aom-color-primary);
      color: var(--aom-color-primary);
    }
</style>